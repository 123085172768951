import { ErrorData } from '@api/types/api-error';
import { AlertProps } from '@components/alerts/alert-props';
import { StyledAlertHeader } from '@components/alerts/styled';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useScrollToOnMount from '@hooks/use-scroll-to-on-mount';
import { useCommonTr } from '@i18n/use-common-tr';
import { Alert, styled, Typography } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';
import { ReactNode } from 'react';

const StyledErrorAlert = styled(Alert)(({ theme: { palette } }) => ({
  backgroundColor: palette.error.light,
  color: palette.common.textDark,
}));

const StyledMessageAlert = styled('div')(() => ({
  backgroundColor: '#fff',
  color: '#000',
  padding: '16px 20px',
  margin: 20,
  borderLeft: '6px solid red',
  display: 'flex',
  alignItems: 'flex-start',
  gap: '12px',
}));

const StyledMessageContent = styled('div')({
  flex: 1,
});

const StyledExpiryMessage = styled(Typography)({
  color: '#D32F2F',
  marginBottom: '4px',
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const StyledIcon = styled(FontAwesomeIcon)({
  marginTop: '2px',
});

export default function ErrorAlert({ title, message, onClose }: AlertProps) {
  useScrollToOnMount('error-alert');

  const { t } = useCommonTr('errors');
  return (
    <StyledErrorAlert id="error-alert" severity="error" onClose={onClose}>
      {title ? <StyledAlertHeader>{title}</StyledAlertHeader> : null}
      <Typography variant="body2">
        {message != null && typeof message === 'string' ? (
          <span dangerouslySetInnerHTML={{ __html: message as string }} />
        ) : message != null ? (
          message
        ) : (
          t('generic')
        )}
      </Typography>
    </StyledErrorAlert>
  );
}

export function TrialExpiryAlertMessage({
  title,
  message,
}: Readonly<{
  title?: string | DefaultTFuncReturn | ErrorData | ReactNode;
  message?: string | DefaultTFuncReturn | ErrorData | ReactNode;
}>) {
  useScrollToOnMount('error-alert');
  const { t } = useCommonTr('errors');

  return (
    <StyledMessageAlert id="trial-expiry-alert">
      <StyledIcon icon={faInfoCircle} size="1x" color="#D32F2F" />
      <StyledMessageContent>
        {title && <StyledExpiryMessage>{title}</StyledExpiryMessage>}
        <Typography variant="body2">
          {message != null && typeof message === 'string' ? (
            <span dangerouslySetInnerHTML={{ __html: message as string }} />
          ) : message != null ? (
            message
          ) : (
            t('generic')
          )}
        </Typography>
      </StyledMessageContent>
    </StyledMessageAlert>
  );
}
