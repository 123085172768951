import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';

type QueryParams = {
  errorMessage?: string;
};

export default function SSOCallbackPage() {
  const { errorMessage } = useSearchParamsTyped<QueryParams>();
  const navigate = useNavigate();

  useEffect(() => {
    if (errorMessage) {
      navigate('/auth/login-error', {
        state: {
          title: 'Account does not exist.',
          message:
            '\n' + 'To create an account contact your system administrator',
        },
      });
    } else {
      navigate('/');
    }
  }, [errorMessage]);

  return <></>;
}
