import { useState, useEffect, PropsWithChildren } from 'react';
import {
  HEADER_PAYLOAD_COOKIE,
  TOKEN_SIGNATURE_COOKIE,
} from '@store/user/user-slice';
import config from 'src/config';
import { useLocation } from 'react-router-dom';

export default function CookieDomainCheck({ children }: PropsWithChildren<{}>) {
  const [isChecking, setIsChecking] = useState(true);
  const location = useLocation();
  const isLoginRoute = location.pathname === '/auth/login';

  useEffect(() => {
    const checkCookieValidity = () => {
      const cookie = document.cookie;
      const cookieItem = cookie
        .split(';')
        .find((c) => c.trim().startsWith(HEADER_PAYLOAD_COOKIE));

      if (!cookieItem) {
        // No cookie found, treat as valid
        return true;
      }

      try {
        const payload = JSON.parse(atob(cookie!.split('.')[1]));

        const expires = new Date(payload.exp * 1000);
        const now = new Date();

        if (now > expires || payload.iss !== config.apiUrl) {
          console.log('Incorrect logging out');
          return false;
        }
      } catch (e) {
        console.log('error trying to check cookie contents', e);
        // Any error during cookie parsing should be treated as an invalid cookie
        return false;
      }

      return true;
    };

    const cookieIsValid = checkCookieValidity();

    if (!isLoginRoute && !cookieIsValid) {
      document.cookie = `${HEADER_PAYLOAD_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      document.cookie = `${TOKEN_SIGNATURE_COOKIE}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
      // Redirect and force a page refresh
      window.location.href = '/auth/login';
    } else {
      setIsChecking(false); // Mark the check as complete
    }
  }, []);

  // Render nothing until the cookie check is complete
  if (isChecking) {
    return null;
  }

  return <>{children}</>;
}
