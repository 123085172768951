import { Dayjs } from 'dayjs';
import { selectTimezone } from '@store/locale/locale-selector';
import { store } from '@store/store';
import { i18nDayJs } from '@i18n/index';

export const getDate = (value: any): Dayjs | null => {
  let date: Date | null = null;
  if (value instanceof Date) {
    date = value;
  } else if (typeof value === 'string') {
    date = new Date(value);
  }

  if (date != null) {
    const timezone = selectTimezone(store.getState());
    return i18nDayJs(date).tz(timezone);
  }

  return null;
};

export const getNowUtc = () => {
  return i18nDayJs().utc();
};

export function adjustCurrentGMTToTimezone(label: string): Date {
  // Get current GMT time
  const currentGMTDate = new Date(Date.now());

  // Extract UTC offset from label
  const utcMatch = label.match(/UTC([+-]\d{2}):(\d{2})/);
  if (!utcMatch) {
    throw new Error('Invalid timezone label format');
  }

  // Parse UTC offset
  const offsetHours = parseInt(utcMatch[1]);
  const offsetMinutes = parseInt(utcMatch[2]);

  // Calculate offset in milliseconds
  const offsetMilliseconds = (offsetHours * 60 + offsetMinutes) * 60 * 1000;

  // Return adjusted date
  return new Date(currentGMTDate.getTime() + offsetMilliseconds);
}
