import { useCreateLocationForCompanyMutation } from '@api/endpoints/company-location.api';
import { CompanyLocationResource } from '@api/types/company/company-location/company-location.resource';
import { PageAlertDisplay } from '@components/alerts';
import { OutlinedButton, PrimaryButton } from '@components/buttons';
import WCTLocationTypeaheadField from '@components/input/location-typeahead-field';
import RadioSelectGroup from '@components/input/radio-select-group';
import WCTSelectField from '@components/input/select-field';
import WCTSwitchField from '@components/input/switch-field';
import WCTTextField from '@components/input/text-field';
import ActionsRow from '@components/layout-util-components/actions-row';
import Column from '@components/layout-util-components/column';
import { Divider } from '@components/layout-util-components/divider';
import { Gap } from '@components/layout-util-components/gap';
import Spacer from '@components/layout-util-components/spacer';
import { ButtonSkeleton, InputSkeleton } from '@components/skeletons';
import ListContainerSkeleton from '@components/skeletons/list-container-skeleton';
import { useUiPopup } from '@components/ui-popup/ui-popup-provider';
import { faCircleInfo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDirtyGuard from '@hooks/use-dirty-guard';
import { Box, Grid, Tooltip, Typography } from '@mui/material';
import FunctionalLocationForm, {
  FunctionalLocationFormProps,
} from '@pages/app/company-profile/forms/functional-location-form';
import { StepFormLayout } from '@pages/app/rca/create/components/step-layout';
import { RcaStepProps } from '@pages/app/rca/create/rca-creation-steps-page';
import useOverviewStep from '@pages/app/rca/create/steps/overview-step-hook';
import { RCA_BOX_TEXT_MAX_LENGTH } from '@pages/app/rca/tabs/components/node-types/rca-default-node';
import CaseIncidentInput from './case-incident/case-incident-input';

export default function OverviewStep({
  onComplete,
  onGoBack,
  caseId,
}: Readonly<RcaStepProps>) {
  const { confirmDirty } = useDirtyGuard();
  const { showPopup } = useUiPopup();

  const state = useOverviewStep(caseId);
  const {
    incidentAtReportingLocation,
    reportingLocation,
    companyLocationOptions,
    incidentLocationOptions,
    frequencyTypeOptions,
    duration,
    priorityOptions,
    priority,
    severityOptions,
    incidentLocation,
    severity,
    caseTypeOptions,
    rcaType,
    caseIncident,
    investigationName,
    frequency,
    frequencySummary,
    focalPoint,
    description,
    isLoading,
    isSubmitting,
    isEdit,
    canSubmit,
    systemText,
    onSubmit,
    caseAnalysisTypeName,
    addNewIncidentLocation,
    projectedIsValid,
    caseIncidentErrors,
    setCaseIncidentErrors,
  } = state;

  const onSubmitClicked = async () => {
    const shouldComplete = await onSubmit();
    if (shouldComplete) {
      onComplete();
    }
  };

  const addNewCompanyLocation = () => {
    return showPopup<FunctionalLocationFormProps, CompanyLocationResource>(
      FunctionalLocationForm,
      {
        creationMutation: useCreateLocationForCompanyMutation,
      }
    );
  };

  return (
    <StepFormLayout
      marginTop={!isEdit}
      onSubmit={onSubmitClicked}
      actionsRow={
        <ActionsRow noAlert className="create-footer">
          {
            <OutlinedButton onClick={confirmDirty(onGoBack)}>
              Back
            </OutlinedButton>
          }
          <Spacer />
          <PrimaryButton
            buttonVariant="two"
            disabled={!canSubmit}
            simulateDisabledState={!projectedIsValid}
            isBusy={isSubmitting}
            type="submit"
          >
            {isEdit ? 'Update' : 'Continue'}
          </PrimaryButton>
        </ActionsRow>
      }
    >
      <Column>
        <PageAlertDisplay ignoreSuccess />
        <Typography variant="h1">
          {caseAnalysisTypeName
            ? `${caseAnalysisTypeName} - Overview`
            : 'Overview'}
        </Typography>
        <Gap size={50} />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="h2">RCA Name</Typography>
            <Gap size={8} />
            <Typography variant="body2">Name your RCA</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Column>
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  label="RCA Name"
                  name="investigationName"
                  value={investigationName.value}
                  error={investigationName.error}
                  onChange={investigationName.set}
                  maxLength={100}
                  required
                />
              )}
              <Gap size={24} />
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  label="Description"
                  name="description"
                  value={description.value}
                  error={description.error}
                  onChange={description.set}
                  required
                  multiline
                />
              )}
            </Column>
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
              <Typography variant="body1">
                {systemText['RCA.Overview.FocalPoint']}
              </Typography>
              <Tooltip
                title={'What is the exact issue being analysed'}
                arrow
                placement="top-start"
              >
                <span>
                  <FontAwesomeIcon icon={faCircleInfo} size="sm" />
                </span>
              </Tooltip>
            </div>
            <Typography variant="body2">
              What is the exact issue being analysed
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTTextField
                label={systemText['RCA.Overview.FocalPoint']}
                name="focalPoint"
                value={focalPoint.value}
                error={focalPoint.error}
                onChange={focalPoint.set}
                maxLength={RCA_BOX_TEXT_MAX_LENGTH}
                required
              />
            )}
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              {systemText['RCA.Overview.BusinessFunctions']}
            </Typography>
            <Typography variant="body2">
              Which {systemText['RCA.Overview.BusinessFunctions']} are involved
              in this issue
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTSelectField
                id="type"
                name="type"
                label="Select"
                options={caseTypeOptions!}
                value={rcaType.value}
                error={rcaType.error}
                onChange={rcaType.set}
                required
                multiple
              />
            )}
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Priority Level</Typography>
            <Typography variant="body2">
              How important is this issue to your organisation
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <ListContainerSkeleton />
            ) : (
              <RadioSelectGroup
                options={priorityOptions!}
                value={priority.value}
                error={priority.error}
                onChange={priority.set}
              />
            )}
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Severity</Typography>
            <Typography variant="body2">
              What is the severity rating of this issue
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <ListContainerSkeleton />
            ) : (
              <RadioSelectGroup
                options={severityOptions!}
                value={severity.value}
                error={severity.error}
                onChange={severity.set}
              />
            )}
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">When</Typography>
            <Typography variant="body2">When did this issue occur</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            {isLoading ? (
              <ListContainerSkeleton />
            ) : (
              <CaseIncidentInput
                value={caseIncident.value}
                setCaseIncident={caseIncident.set}
                setCaseIncidentErrors={setCaseIncidentErrors}
                errors={caseIncidentErrors}
              />
            )}
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">
              {systemText['RCA.Overview.Recurrence']}
            </Typography>
            <Typography variant="body2">
              {`What is the ${systemText['RCA.Overview.Recurrence']} rate of this issue`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Column>
              <Grid container wrap="nowrap">
                <Box maxWidth={110}>
                  {isLoading ? (
                    <InputSkeleton />
                  ) : (
                    <WCTTextField
                      name="frequency"
                      label="Frequency"
                      value={frequency.value}
                      error={frequency.error}
                      onChange={frequency.set}
                      required
                    />
                  )}
                </Box>
                <Gap size={32} />
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <WCTSelectField
                    id="duration"
                    name="duration"
                    label="Per Period"
                    value={duration.value}
                    error={duration.error}
                    onChange={duration.set}
                    options={frequencyTypeOptions!}
                    required
                  />
                )}
              </Grid>
              <Gap size={32} />
              {isLoading ? (
                <InputSkeleton />
              ) : (
                <WCTTextField
                  name="frequencySummary"
                  label="Additional Notes"
                  value={frequencySummary.value}
                  error={frequencySummary.error}
                  onChange={frequencySummary.set}
                />
              )}
            </Column>
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Reporting Location</Typography>
            <Typography variant="body2">
              Which office or site is conducting this RCA
            </Typography>
          </Grid>
          <Grid container flexDirection="column" item xs={12} md={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : companyLocationOptions!.length > 0 ||
              !!reportingLocation.value ? (
              <WCTLocationTypeaheadField
                id="reportingLocation"
                name="reportingLocation"
                placeholder="Type to search"
                label="Reporting Location"
                value={reportingLocation.value}
                error={reportingLocation.error}
                onChange={reportingLocation.set}
                options={companyLocationOptions}
                groupBy={(_) => 'Locations'}
                required
              />
            ) : null}
            <Gap size={20} />
            <Box maxWidth={230}>
              {isLoading ? (
                <ButtonSkeleton />
              ) : (
                <OutlinedButton
                  onClick={() => {
                    addNewCompanyLocation().then((location) => {
                      if (location != null) {
                        reportingLocation.set(location);
                      }
                    });
                  }}
                >
                  {!companyLocationOptions?.length && !reportingLocation.value
                    ? 'Add Your First Location'
                    : 'Add New Reporting Location'}
                </OutlinedButton>
              )}
            </Box>
          </Grid>
        </Grid>
        <Divider addMargin />
        <Grid container>
          <Grid item xs={12} md={6}>
            <Typography variant="body1">Incident Location</Typography>
          </Grid>
          <Grid container flexDirection="column" item xs={12} md={6}>
            {isLoading ? (
              <InputSkeleton />
            ) : (
              <WCTSwitchField
                name="incidentAtReportingLocation"
                value={incidentAtReportingLocation.value}
                label="Is different from the reporting location"
                error={incidentAtReportingLocation.error}
                onChange={incidentAtReportingLocation.set}
              />
            )}
          </Grid>
        </Grid>
        {incidentAtReportingLocation.value != null &&
        incidentAtReportingLocation.value ? (
          <>
            <Gap size={32} />
            <Grid container>
              <Grid item xs={12} md={6}>
                <Typography variant="body1">
                  Where did the incident occur
                </Typography>
              </Grid>
              <Grid container flexDirection="column" item xs={12} md={6}>
                {isLoading ? (
                  <InputSkeleton />
                ) : (
                  <WCTLocationTypeaheadField
                    id="incidentLocation"
                    name="incidentLocation"
                    placeholder="Type to search"
                    label="Incident Location"
                    value={incidentLocation.value}
                    error={incidentLocation.error}
                    onChange={incidentLocation.set}
                    options={incidentLocationOptions}
                    required
                  />
                )}
                <Gap size={20} />
                <Box maxWidth={230}>
                  {isLoading ? (
                    <ButtonSkeleton />
                  ) : (
                    <OutlinedButton onClick={addNewIncidentLocation}>
                      Add New Incident Location
                    </OutlinedButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </>
        ) : null}
      </Column>
    </StepFormLayout>
  );
}
