import { Box, styled, Typography } from '@mui/material';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

interface Props {
  icon?: ReactElement;
  indicatorContent?: ReactNode;
  onClick: () => void;
  isSelected?: boolean;
  outline?: boolean;
  className?: string;
  disabled?: boolean;
}

const StyledContainer = styled('div', { target: 'menu-item-row' })<{
  isSelected: boolean;
  outline: boolean;
  disabled?: boolean;
}>(({ isSelected, outline, theme, disabled = false }) => ({
  ...(outline && {
    marginLeft: '18px',
    marginRight: '18px',
    width: '239px',
    borderRadius: '30px',
    padding: '10px',
    paddingLeft: 10,
    border: `2px solid ${theme.palette.common.grey30}`,
  }),
  '&:hover': {
    backgroundColor: theme.palette.common.grey10,
  },
  transition: 'all 0.2s ease-in-out',
  display: 'flex',
  padding: 10,
  alignItems: 'center',
  gap: 5,
  alignSelf: 'stretch',
  borderRadius: 5,
  width: 215,
  cursor: disabled ? 'default' : 'pointer',
  opacity: disabled ? 0.5 : 1,
  ...(isSelected && {
    cursor: 'default',
    background: 'linear-gradient(90deg, #FF6C00 0%, #FF6C00 100%)',
    color: theme.palette.blue.dark,

    '&:hover': {
      background: 'linear-gradient(90deg, #FF6C00 0%, #FF6C00 100%)',
    },
  }),
  '&.selected': {
    '& svg': {
      color: theme.palette.blue.dark,
      '& path': {
        fill: theme.palette.blue.dark,
      },
    },
  },
}));

const IconContainer = styled('div')<{ isSelected: boolean }>(
  ({ isSelected, theme }) => ({
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: '0 0 auto',
    width: 'auto',
    height: 18,
    '& svg': {
      maxWidth: '100%',
      maxHeight: '100%',
      height: '100%',
      width: 'auto',
      color: theme.palette.common.textMid,
      '& path': {
        fill: theme.palette.common.textMid,
      },
    },
  })
);

const StyledIndicatorContainer = styled('div')(({ theme: { palette } }) => ({
  flex: '0 0 auto',
  background: palette.primary.main,
  width: 18,
  height: 18,
  borderRadius: '50%',
  fontSize: '12px',
  fontWeight: '600',
  lineHeight: '18px',
  letterSpacing: '0.15px',
  textAlign: 'center',
  color: palette.common.white,
}));

export default function MenuItemRow({
  icon,
  indicatorContent,
  onClick,
  isSelected,
  outline,
  children,
  disabled,
}: PropsWithChildren<Props>) {
  return (
    <StyledContainer
      isSelected={isSelected ?? false}
      outline={outline ?? false}
      onClick={onClick}
      className={isSelected ? 'selected' : ''}
      disabled={disabled}
    >
      {icon ? (
        <IconContainer isSelected={isSelected ?? false}>{icon}</IconContainer>
      ) : null}
      <Box flexGrow={1} className="menu-item-descriptor">
        <Typography
          variant="body1"
          fontSize={16}
          fontWeight={400}
          lineHeight="29px"
          letterSpacing={-0.5}
        >
          {children}
        </Typography>
        {indicatorContent ? (
          <StyledIndicatorContainer>
            {indicatorContent}
          </StyledIndicatorContainer>
        ) : null}
      </Box>
    </StyledContainer>
  );
}
