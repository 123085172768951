import { Box, Grid, Link, styled, Typography } from '@mui/material';
import WCTTextField from '@components/input/text-field';
import { useLocation, useNavigate } from 'react-router-dom';
import useLogin, { LoginResultAction } from '@pages/auth/login-hook';
import WCTCheckboxField from '@components/input/checkbox-field';
import { PageAlertDisplay, usePageAlertVariants } from '@components/alerts';
import { useAuthTr } from '@i18n/use-auth-tr';
import NoAccountSignupLink from '@pages/auth/components/no-account-signup-link';
import { useAppDispatch } from '@store/store';
import { refreshUserState } from '@store/user/user-slice';
import { useEffect } from 'react';
import { isApp } from '@util/env';
import useUser from '@store/user/user-hook';
import useSearchParamsTyped from '@hooks/router-dom-helper-hooks';
import { PrimaryButton } from '@components/buttons';
import config from 'src/config';
import { LoadingIndicator } from '@components/loading-indicator';

type QueryParams = {
  inviteToken?: string;
};

export interface LoginPageLocationState {
  prompt?: 'password-reset-success' | 'logout-success';
  returnTo?: string;
  noRedirect?: boolean;
}

const StyledBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginTop: -10,
  '.MuiFormControlLabel-root': {
    gap: 4,
  },
});

export default function LoginPage() {
  const { inviteToken } = useSearchParamsTyped<QueryParams>();
  const { isLoggedInAndFullyAuthenticated } = useUser();
  const {
    email,
    password,
    rememberMe,
    submit,
    canSubmit,
    isLoading,
    installConfiguration,
    loginOptions,
  } = useLogin(inviteToken);

  const canSignup = isApp && installConfiguration?.allowPublicSignups === true;

  const { t } = useAuthTr('login');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { state } = useLocation();
  const { prompt, returnTo, noRedirect } = (state ||
    {}) as LoginPageLocationState;

  const { showSuccessAlert } = usePageAlertVariants();

  useEffect(() => {
    if (!!prompt) {
      switch (prompt) {
        case 'logout-success':
          showSuccessAlert({
            title: t('prompts.logout'),
          });
          break;
        case 'password-reset-success': {
          showSuccessAlert({
            title: t('prompts.passwordReset.title'),
            message: t('prompts.passwordReset.message'),
          });
          break;
        }
      }
    }
  }, [prompt, showSuccessAlert, t]);

  const onSubmit = async () => {
    const action = await submit();
    if (action == null) {
      return;
    }

    switch (action) {
      case LoginResultAction.Proceed:
        dispatch(refreshUserState());
        navigate(returnTo || '/', { replace: true });
        break;
      case LoginResultAction.InvalidAttemptsError:
        navigate('/auth/login-error', {
          state: {
            title:
              'Your account has been locked due to too many failed login attempts.',
            message:
              '\n' +
              'To unlock your account you will need to <a href="/auth/forgot-password">reset your password</a> and follow the instructions.',
          },
        });

        break;
      case LoginResultAction.LockoutDueToInactivityError:
        navigate('/auth/login-error', {
          state: {
            title:
              'Your account has been locked due to an extended period of inactivity.',
            message:
              '\n' +
              'To unlock your account you will need to <a href="/auth/forgot-password">reset your password</a> and follow the instructions.',
          },
        });

        break;
      default:
        break;
    }
  };

  // if (isLoggedInAndFullyAuthenticated && !noRedirect) {
  //   return <Navigate to="/" />;
  // }

  const navigateToSamlLogin = () => {
    window.location.href = `${config.apiUrl}/api/auth/saml/login?redirectUrl=${window.location.origin}/sso/callback`;
  };

  return (
    <Grid
      component="form"
      noValidate
      container
      position="relative"
      direction="column"
      gap="25px"
      justifyContent="center"
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Typography variant="h2" style={{ fontSize: '32px' }}>
        {t('title')}
      </Typography>
      <PageAlertDisplay />
      <Typography fontSize={18} variant="body1" color="common.textMid">
        {t('subtitle')}
      </Typography>
      {!isLoading ? (
        <>
          {loginOptions?.local && (
            <>
              <WCTTextField
                label={t('form.email')}
                name="email"
                value={email.value}
                error={email.error}
                onChange={email.set}
                required
              />

              <WCTTextField
                label={t('form.password')}
                name="password"
                value={password.value}
                error={password.error}
                onChange={password.set}
                required
                obscure
              />

              <StyledBox>
                <WCTCheckboxField
                  label={t('form.rememberMe')}
                  value={rememberMe.value}
                  onChange={rememberMe.set}
                />
                <Link href="../forgot-password" color="common.textDark">
                  {t('forgotPasswordLink')}
                </Link>
              </StyledBox>

              <PrimaryButton type="submit" size="large" disabled={!canSubmit}>
                <Typography>{t('buttonLabel')}</Typography>
              </PrimaryButton>
            </>
          )}
          {loginOptions?.saml && (
            <>
              <PrimaryButton size="large" onClick={navigateToSamlLogin}>
                <Typography>
                  Login using {loginOptions.samlProviderName}
                </Typography>
              </PrimaryButton>
            </>
          )}
          {canSignup && <NoAccountSignupLink />}
        </>
      ) : (
        <Box style={{ position: 'relative' }}>
          <LoadingIndicator show />
        </Box>
      )}
    </Grid>
  );
}
